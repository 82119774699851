import { LineChart } from "@mui/x-charts/LineChart";
import React from "react";
import { Company, CompanyApiResponse, formatMonthDate } from "../../types/data";
import { baseURL } from "../../../config";

const getRealTimeDates = (days: number) => {
  const datesArray = [];
  for (let i = 0; i < days; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i); // Go back 'i' days
    datesArray.unshift(
      date.toLocaleDateString("en-US", { month: "numeric", day: "numeric" })
    );
  }
  return datesArray;
};

const DataGraphs = () => {
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const xAxisDates = getRealTimeDates(7);

  const fetchDocuments = async () => {
    try {
      const response = await fetch(baseURL + "/user-data/all", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setCompanyData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchDocuments();
  }, []);

  const dateFiltered = companyData.map((value) => value.created_at);
  const result = dateFiltered.map((date) => formatMonthDate(date));
  result.reduce((acc: { [key: string]: number }, date) => {
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  return (
    <div>
      <LineChart
        xAxis={[{ data: xAxisDates }]}
        series={[
          {
            data: [2, -5.5, 2, -7.5, 1.5, 6],
            area: true,
            baseline: "min",
          },
        ]}
        width={500}
        height={300}
      />
    </div>
  );
};

export default DataGraphs;
