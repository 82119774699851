import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import OrderTable from "./OrderTable";
import { Company, CompanyApiResponse } from "../../../../types/data";
import {
  DataWrapper,
  UserDataCompany,
  UserDataContainer,
  UserDataCountry,
  UserDataUniversity,
} from "../../../../styles/style";
import { baseURL } from "../../../../../config";

export default function AutocadTable() {
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [totalCount, setTotalCount] = React.useState<number | null>(null);

  const fetchDocuments = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(baseURL + "/user-data/autocad", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setCompanyData(data.data);
      setTotalCount(data.after_filtering_count);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchDocuments();
  }, []);

  const handleDownload = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        baseURL + "/user-data/download-excel-autocad",
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = urlObject;
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "autocad-data.xlsx";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "")
          .trim();
      }
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const studentCount = companyData.filter((item) => item.place_of_study).length;
  const employeeeCount = companyData.filter((item) => item.workplace).length;
  const uniqueCountries = new Set(companyData.map((item) => item.country));
  const countryCount = uniqueCountries.size;

  React.useEffect(() => {}, [studentCount]);

  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Typography fontWeight={500} fontSize={12}>
              학원
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              Autocad 데이터
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography level="h2" component="h1" sx={{ flex: 1 }}>
              Autocad 데이터
            </Typography>
            <Button onClick={handleDownload}>Download Excel</Button>
          </Box>
          <DataWrapper>
            <UserDataContainer>
              <h1>Total:</h1>
              <p>{companyData.length}</p>
            </UserDataContainer>
            <UserDataCountry>
              <h1>Country:</h1>
              <p>{countryCount}</p>
            </UserDataCountry>
            <UserDataCompany>
              <h1>Student:</h1>
              <p>{studentCount}</p>
            </UserDataCompany>
            <UserDataUniversity>
              <h1>Employee:</h1>
              <p>{employeeeCount}</p>
            </UserDataUniversity>
          </DataWrapper>
        </Box>

        <OrderTable />
      </Box>
    </>
  );
}
