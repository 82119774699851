import ControlTable from "./controlListTable";

const AuthMaker = () => {
  return (
    <div>
      <ControlTable />
    </div>
  );
};

export default AuthMaker;
