import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { Company, CompanyApiResponse } from "../../../types/data";
import SearchIcon from "@mui/icons-material/Search";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState } from "react";
import { baseURL } from "../../../../config";
import { Typography } from "@mui/joy";
import { JobOffer, JobOffergApiResponse } from "../../../types/jobOffer";
import { Link } from "react-router-dom";

export default function JobOfferTable() {
  const [offerData, setOfferData] = React.useState<JobOffer[]>([]);
  const [filteredData, setFilteredData] = React.useState<JobOffer[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [paginationData, setPaginationData] = useState<any>({
    after_filtering_count: 0,
    current_page: 1,
    next_page: null,
    page_count: 0,
  });

  const [page, setPage] = useState(paginationData.current_page);

  React.useEffect(() => {
    fetchDocuments(page);
    // fetchAdmins();
  }, [page]);

  const handlePageChange = async (newPage: number) => {
    await fetchDocuments(newPage);
    setPage(newPage);
  };

  const fetchDocuments = async (page: number) => {
    const token = localStorage.getItem("token");
    let allData: Company[] = [];
    try {
      const response = await fetch(
        baseURL + "/company-job-offer/all?page=" + page.toString(),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: JobOffergApiResponse = await response.json();
      setOfferData(data.data);
      setFilteredData(data.data);
      setPaginationData({
        after_filtering_count: data.after_filtering_count,
        current_page: data.current_page,
        next_page: data.next_page,
        page_count: data.page_count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = offerData.filter(
        (company) =>
          company.business_name.toLowerCase().includes(query.toLowerCase()) ||
          company.visa.toLowerCase().includes(query.toLowerCase()) ||
          company.business_registration_certificate
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          company.contact_person.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(offerData);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const totalNumbers = 6;
    const sideNumbers = 1;

    if (paginationData.page_count <= totalNumbers) {
      for (let i = 1; i <= paginationData.page_count; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(2, page - sideNumbers);
      let endPage = Math.min(paginationData.page_count - 1, page + sideNumbers);

      if (page <= sideNumbers + 2) {
        startPage = 2;
        endPage = totalNumbers - 1;
      } else if (page >= paginationData.page_count - sideNumbers - 1) {
        startPage = paginationData.page_count - totalNumbers + 2;
        endPage = paginationData.page_count - 1;
      }

      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < paginationData.page_count - 1) pages.push("...");
      pages.push(paginationData.page_count);
    }

    return pages;
  };

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "flex", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ fontSize: 14 }}>Search for user</FormLabel>
          <Input
            size="sm"
            placeholder="Search by name, country, or phone"
            startDecorator={<SearchIcon />}
            value={searchQuery}
            onChange={handleSearch}
          />
        </FormControl>
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "flex", sm: "flex" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                번호
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                사업체명
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                담당자 연락처
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>비자</th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                근무지
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                모집인원
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                근무시간
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>교통</th>
              <th
                style={{
                  padding: "12px 6px",
                  textAlign: "center",
                }}
              >
                보기
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((company, index) => (
              <tr key={company._id}>
                <td style={{ textAlign: "center", width: 120 }}>
                  <Typography level="body-xs">
                    {Number(index + 1) < 10
                      ? (page - 1).toString() + Number(index + 1)
                      : page + "0"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.business_name || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.contact_person || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.visa || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.work_place || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.number_of_recruits || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.working_hours || "---"}
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Typography level="body-xs">
                    {company.traffic || "no date"}
                  </Typography>
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Link
                      to={`/company-job/${company._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        level="body-xs"
                        color="primary"
                        fontSize={13}
                        sx={{ cursor: "pointer" }}
                      >
                        view
                      </Typography>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </Button>
        <Box sx={{ flex: 1 }} />
        {renderPageNumbers().map((pageNumber, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={pageNumber === page ? "solid" : "outlined"}
            color="neutral"
            onClick={() =>
              typeof pageNumber === "number" && handlePageChange(pageNumber)
            }
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(page + 1)}
          disabled={page >= paginationData.page_count}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
