import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { baseURL } from "../../../../config";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const JobOfferDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [worker, setWorker] = useState<any>(null);

  useEffect(() => {
    const fetchWorker = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${baseURL}/worker/get/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setWorker(response.data.data);
      } catch (error) {
        console.error("Error fetching worker detail:", error);
      }
    };

    if (id) {
      fetchWorker();
    }
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const fileUrls: any = [];

  if (worker?.cv) {
    fileUrls.push(worker?.cv);
  }

  const downloadAllAsZip = async () => {
    try {
      const zip = new JSZip();

      if (fileUrls.length === 0) {
        toast.info("Files not found!");
        return;
      }

      for (let i = 0; i < fileUrls.length; i++) {
        const fileUrl = fileUrls[i];

        const fileName = fileUrl.split("/").pop() || `file-${i}`;

        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Failed to download file: ${fileUrl}`);
        }
        const blob = await response.blob();

        zip.file(fileName, blob);
      }

      const zipContent = await zip.generateAsync({ type: "blob" });

      saveAs(zipContent, worker.first_name + "'s all files.zip");
    } catch (err) {
      console.error(err);
    }
  };

  const getYoutubeEmbedUrl = (originalUrl: string): string => {
    if (!originalUrl.startsWith("https://")) {
      return "https://www.youtube.com/embed/undefined";
    }

    const url = new URL(originalUrl);

    let videoId = "";
    let searchParams = "";

    const hostname = url.hostname.replace("www.", "");

    if (hostname === "youtu.be") {
      videoId = url.pathname.slice(1);
      searchParams = url.search;
    } else if (hostname === "youtube.com") {
      if (url.pathname === "/watch") {
        const vParam = url.searchParams.get("v");
        if (vParam) {
          videoId = vParam;
          url.searchParams.delete("v");
        }
        const remainingParams = url.searchParams.toString();
        if (remainingParams) {
          searchParams = "?" + remainingParams;
        }
      } else if (url.pathname.startsWith("/embed/")) {
        const pathParts = url.pathname.split("/");
        if (pathParts.length > 2) {
          videoId = pathParts[2];
        }
        searchParams = url.search;
      }
    }

    let embedUrl = "";
    if (videoId) {
      embedUrl = "https://www.youtube.com/embed/" + videoId + searchParams;
    }

    return embedUrl;
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${baseURL}/worker/delete/${id}`, {
        method: "DELETE",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success("Deleted successfully");
        navigate("/outsourcing-civilengineering-data");
      } else {
        console.error(
          "Failed to delete worker",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting worker:", error);
    }
  };

  return (
    <Box>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Stack
            spacing={4}
            sx={{
              display: "flex",
              mx: "auto",
              px: { xs: 2, md: 6 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Card>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  component="a"
                  href={worker?.cv}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (!worker?.cv) {
                      toast.info("CV not upload!");
                    }
                  }}
                >
                  Download CV
                </Button>
                <Button color="success" onClick={downloadAllAsZip}>
                  Download All Files [zip]
                </Button>
              </Box>
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  {/* TOP SECTION */}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box sx={{ backgroundColor: "#f5f5f5" }}>
                      <Stack direction="column" spacing={2} alignItems="center">
                        {!worker?.avatar && (
                          <Avatar
                            sx={{ borderRadius: 0, width: 150, height: 150 }}
                          />
                        )}
                        {worker?.avatar && (
                          <Box
                            sx={{
                              width: 150,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <img
                              src={worker?.avatar}
                              alt="preview"
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Stack direction="row" gap={2}>
                        <Box sx={{ flexGrow: 1 }}>
                          <FormLabel>성</FormLabel>
                          <Input
                            size="sm"
                            placeholder="First name"
                            value={worker?.first_name || ""}
                            readOnly
                          />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <FormLabel>명</FormLabel>
                          <Input
                            size="sm"
                            placeholder="Last name"
                            value={worker?.last_name || ""}
                            readOnly
                          />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <FormLabel>생열월일</FormLabel>
                          <Input
                            size="sm"
                            placeholder="Birthday"
                            value={worker?.birthday || ""}
                            readOnly
                          />
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                        <FormControl>
                          <FormLabel>국적</FormLabel>
                          <Input
                            size="sm"
                            value={worker?.country || ""}
                            readOnly
                          />
                        </FormControl>
                        <FormControl sx={{ flexGrow: 1 }}>
                          <FormLabel>주소</FormLabel>
                          <Input
                            size="sm"
                            value={worker?.address || ""}
                            readOnly
                          />
                        </FormControl>
                      </Stack>
                    </Box>
                  </Box>

                  {/* CONTACT SECTION */}
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>이메일</FormLabel>
                      <Input size="sm" value={worker?.email || ""} readOnly />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>전화번호</FormLabel>
                      <Input size="sm" value={worker?.phone || ""} readOnly />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>전화번호 2</FormLabel>
                      <Input size="sm" value={worker?.phone_2 || ""} readOnly />
                    </FormControl>
                  </Stack>

                  {/* PERSONAL INFORMATION */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Shaxsiy Ma'lumotlar
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Height</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.height || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Weight</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.weight || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Oyoq Razmer</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.foot_size || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Qon guruhi</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.blood_type || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Din</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.religion || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Nikoh</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.personal_information?.marriage || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>

                  {/* URGENT CONTACT */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Shoshilinch a'loqa uchun
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Munosabaat</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.urgent_contact?.attitude || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>FIO</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.urgent_contact?.full_name || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Tug'ilgan sana</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.urgent_contact?.birthday || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Phone number</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.urgent_contact?.phone || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>

                  {/* COLLEGE */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Kollej
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Oliygoh nomi</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.college?.name || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Yo'nalish</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.college?.specialty || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Boshlagan sana</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.college?.star_date || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Bitirgan sana</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.college?.final_date || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>

                  {/* UNIVERSITY */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    University
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Eng yuqori daraja</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.university?.education_level || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Oliygoh nomi</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.university?.name || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Yo'nalish</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.university?.specialty || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Boshlagan sana</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.university?.star_date || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Bitirgan sana</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.university?.final_date || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>

                  {/* OTHER INFORMATION */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Qo'shimcha ma'lumot
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl>
                      <FormLabel>Alergiya</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.other_information?.allergy || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Uy-joy mulkini turi</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.other_information?.ownership || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Qarzdorlik haqida</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.other_information?.debt || ""}
                        readOnly
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Qayta turmush qurganmi</FormLabel>
                      <Input
                        size="sm"
                        value={worker?.other_information?.remarried || ""}
                        readOnly
                      />
                    </FormControl>
                  </Stack>

                  {/* FOREIGN EXPERIENCE ARRAY */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Chet-el tajribasi:
                  </Typography>
                  {worker?.foreign_experience?.length > 0 ? (
                    worker.foreign_experience.map(
                      (item: any, index: number) => {
                        if (item?.file) {
                          fileUrls.push(item.file);
                        }
                        return (
                          <Stack
                            direction="row"
                            spacing={2}
                            key={index}
                            sx={{ mb: 2 }}
                          >
                            <FormControl>
                              <FormLabel>
                                Tashrif buyurgan davlat nami:
                              </FormLabel>
                              <Input
                                size="sm"
                                value={item.country || ""}
                                readOnly
                              />
                            </FormControl>
                            <FormControl sx={{ flexGrow: 1 }}>
                              <FormLabel>Qancha vaqt bo'lgan</FormLabel>
                              <Input
                                size="sm"
                                value={item.trip_duration || ""}
                                readOnly
                              />
                            </FormControl>
                            <FormControl sx={{ flexGrow: 1 }}>
                              <FormLabel>Maqsad</FormLabel>
                              <Input
                                size="sm"
                                value={item.goal || ""}
                                readOnly
                              />
                            </FormControl>
                            <FormControl sx={{ flexGrow: 1 }}>
                              <FormLabel>File Link</FormLabel>
                              {item.file ? (
                                <Button
                                  component="a"
                                  href={item.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  variant="outlined"
                                  color="neutral"
                                >
                                  Open File
                                </Button>
                              ) : (
                                <Input size="sm" value="No file" readOnly />
                              )}
                            </FormControl>
                          </Stack>
                        );
                      }
                    )
                  ) : (
                    <Typography>No foreign experience</Typography>
                  )}

                  {/* LANGUAGE ARRAY */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Til bilish darajasi:
                  </Typography>
                  {worker?.language?.length > 0 ? (
                    worker.language.map((item: any, index: number) => {
                      if (item?.file) {
                        fileUrls.push(item.file);
                      }
                      return (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          sx={{ mb: 2 }}
                        >
                          <FormControl>
                            <FormLabel>Til nomi:</FormLabel>
                            <Input size="sm" value={item.name || ""} readOnly />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Daraja</FormLabel>
                            <Input
                              size="sm"
                              value={item.degree || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Nutuq darajasi</FormLabel>
                            <Input
                              size="sm"
                              value={item.speaking || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Yozish va O'qish darajasi</FormLabel>
                            <Input
                              size="sm"
                              value={item.writing_and_reading || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>File Link</FormLabel>
                            {item.file ? (
                              <Button
                                component="a"
                                href={item.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                color="neutral"
                              >
                                Open File
                              </Button>
                            ) : (
                              <Input size="sm" value="No file" readOnly />
                            )}
                          </FormControl>
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography>No language info</Typography>
                  )}

                  {/* CERTIFICATE ARRAY */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Kasbiy Sertifikat
                  </Typography>
                  {worker?.certificate?.length > 0 ? (
                    worker.certificate.map((item: any, index: number) => {
                      if (item?.file) {
                        fileUrls.push(item.file);
                      }
                      return (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          sx={{ mb: 2 }}
                        >
                          <FormControl>
                            <FormLabel>Sertifikat Turi:</FormLabel>
                            <Input size="sm" value={item.type || ""} readOnly />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Berilgan joy</FormLabel>
                            <Input
                              size="sm"
                              value={item.given_place || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Berilgan sana</FormLabel>
                            <Input size="sm" value={item.date || ""} readOnly />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>File Link</FormLabel>
                            {item.file ? (
                              <Button
                                component="a"
                                href={item.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                color="neutral"
                              >
                                Open File
                              </Button>
                            ) : (
                              <Input size="sm" value="No file" readOnly />
                            )}
                          </FormControl>
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography>No certificate info</Typography>
                  )}

                  {/* WORK EXPERIENCE ARRAY */}
                  <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                    Hozirgi ish joyi haqida ma'lumot
                  </Typography>
                  {worker?.work_experience?.length > 0 ? (
                    worker.work_experience.map((item: any, index: number) => {
                      if (item?.file) {
                        fileUrls.push(item.file);
                      }
                      return (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          sx={{ mb: 2 }}
                        >
                          <FormControl>
                            <FormLabel>Shirkat nomi:</FormLabel>
                            <Input size="sm" value={item.name || ""} readOnly />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Kirgan sana</FormLabel>
                            <Input
                              size="sm"
                              value={item.start_date || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Ishlash davri (yil)</FormLabel>
                            <Input
                              size="sm"
                              value={item.experience_year || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Yo'nalish</FormLabel>
                            <Input
                              size="sm"
                              value={item.specialty || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Lavozim</FormLabel>
                            <Input
                              size="sm"
                              value={item.position || ""}
                              readOnly
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>File Link</FormLabel>
                            {item.file ? (
                              <Button
                                component="a"
                                href={item.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                color="neutral"
                              >
                                Open File
                              </Button>
                            ) : (
                              <Input size="sm" value="No file" readOnly />
                            )}
                          </FormControl>
                        </Stack>
                      );
                    })
                  ) : (
                    <Typography>No work experience info</Typography>
                  )}

                  <Box>
                    {worker?.interview_video && (
                      <Box>
                        <Typography>Interview video</Typography>
                        <iframe
                          width="600"
                          height="315"
                          src={getYoutubeEmbedUrl(worker?.interview_video)}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      </Box>
                    )}

                    {worker?.skills_verification_video && (
                      <Box>
                        <Typography>Skills verification video</Typography>
                        <iframe
                          width="600"
                          height="315"
                          src={getYoutubeEmbedUrl(
                            worker?.skills_verification_video
                          )}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      color="danger"
                      sx={{ width: 100 }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                    <Button sx={{ width: 100 }} onClick={handleBackClick}>
                      Back
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default JobOfferDetail;
