import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./components/navigate/Sidebar";
import Header from "./components/others/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import GeneralMemberList from "./components/pages/GeneralMemberManagement/generalMemberList";
import MyProfile from "./components/pages/MyProfile";
import CorporateMemberList from "./components/pages/corporateMember/corporateMemberList";
import ControlComponent from "./components/pages/control";
import ResumeManagement from "./components/pages/GeneralMemberManagement/resume";
import VisaNews from "./components/pages/visaNews";
import AddNews from "./components/pages/visaNews/addNews";
import Documentation from "./components/pages/documentation";
import AddDocumentation from "./components/pages/documentation/addDocumentation";
import JobPostingTabs from "./components/pages/corporateMember/jobPostingManagement";
import SignIn from "./components/pages/auth/singIn";
import DetailPage from "./components/pages/GeneralMemberManagement/detailPage";
import "./index.css";
import ReportDataComponent from "./components/pages/report";
import WeldingComponent from "./components/pages/education/welding";
import AutocadComponent from "./components/pages/education/autocad";
import ITComponent from "./components/pages/education/IT";
import ChattingComponent from "./components/pages/chat";
import ProtectedRoute from "./components/route/ProtectedRoute";
import DetailData from "./components/constant/tables/report/detailData";
import LinkGenerate from "./components/pages/context/linkGenerate";
import CompanyDetail from "./components/constant/tables/corporateManagement/CompanyDatail";
import PostingDetail from "./components/pages/corporateMember/postingDetail";
import VisaDetail from "./components/constant/tables/visa/visaDetail";
import JobOfferComponent from "./components/constant/tables/jobOffer/JobOffer";
import DetailOfferComponent from "./components/constant/tables/jobOffer/detailData";
import ToDoTableMain from "./components/constant/tables/data/todo";
import ProccessTableMain from "./components/constant/tables/data/proccess";
import DoneTableMain from "./components/constant/tables/data/done";
import DataGraphs from "./components/constant/graphs";
import DeveloperData from "./components/pages/outsourcing/dev/developerData";
import CivilEngineering from "./components/pages/outsourcing/civilEngineering/civilEngineering";
import OutSourcingCompany from "./components/pages/outsourcing/company/company";
import AuthMaker from "./components/pages/outsourcing/auth-maker/authMaker";
import JobOfferDetail from "./components/pages/outsourcing/civilEngineering/jobOfferDetail";
import WorkerListSingle from "./components/pages/outsourcing/auth-maker/workers";
import MechanicalEngineer from "./components/pages/outsourcing/mechanical-engineer";
import ConstructionRebar from "./components/pages/outsourcing/construction-rebar";
import AutomobileManufacturing from "./components/pages/outsourcing/automobile-manufacturing";
import PaintingMaintenance from "./components/pages/outsourcing/painting-maintenance";

export default function JoyOrderDashboardTemplate() {
  const location = useLocation();
  const hideSidebar = location.pathname === "/";
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        {!hideSidebar && <Sidebar />}
        {!hideSidebar && <Header />}
        <>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
              path="/general-member-list"
              element={
                <ProtectedRoute allowedPosition="general_member_table">
                  <GeneralMemberList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/general-member-list/:id"
              element={
                <ProtectedRoute allowedPosition="general_member_table">
                  <DetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/resume-management"
              element={
                <ProtectedRoute allowedPosition="general_member_table">
                  <ResumeManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/corporate-member-list"
              element={
                <ProtectedRoute allowedPosition="company_table">
                  <CorporateMemberList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/corporate-member-list/:id"
              element={
                <ProtectedRoute allowedPosition="company_table">
                  <CompanyDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/job-posting-management"
              element={
                <ProtectedRoute allowedPosition="company_table">
                  <JobPostingTabs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/job-posting-management/:id"
              element={
                <ProtectedRoute allowedPosition="company_table">
                  <PostingDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute allowedPosition="chatting_table">
                  <ChattingComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/control"
              element={
                <ProtectedRoute allowedPosition="control">
                  <ControlComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute allowedPosition="allow">
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visa-news"
              element={
                <ProtectedRoute allowedPosition="visa_news_table">
                  <VisaNews />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visa-news/:id"
              element={
                <ProtectedRoute allowedPosition="visa_news_table">
                  <VisaDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visa-news/add-news"
              element={
                <ProtectedRoute allowedPosition="visa_news_table">
                  <AddNews />
                </ProtectedRoute>
              }
            />
            <Route
              path="/documentation"
              element={
                <ProtectedRoute allowedPosition="documentation_table">
                  <Documentation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/documentation/add-document"
              element={
                <ProtectedRoute allowedPosition="documentation_table">
                  <AddDocumentation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/education/welding-data"
              element={
                <ProtectedRoute allowedPosition="welding_table">
                  <WeldingComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/education/welding-data/:id"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <DetailData />
                </ProtectedRoute>
              }
            />
            <Route
              path="/education/autocad-data"
              element={
                <ProtectedRoute allowedPosition="autocad_table">
                  <AutocadComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/education/IT-data"
              element={
                <ProtectedRoute allowedPosition="IT_table">
                  <ITComponent />
                </ProtectedRoute>
              }
            />

            <Route
              path="/company-job"
              element={
                <ProtectedRoute allowedPosition="job_offer">
                  <JobOfferComponent />
                </ProtectedRoute>
              }
            />

            <Route
              path="/company-job/:id"
              element={
                <ProtectedRoute allowedPosition="job_offer">
                  <DetailOfferComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/data/report-data"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <ReportDataComponent />
                </ProtectedRoute>
              }
            />

            <Route
              path="/data/todo_table"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <ToDoTableMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/data/proccess_table"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <ProccessTableMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/data/done_table"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <DoneTableMain />
                </ProtectedRoute>
              }
            />

            <Route
              path="/report-data/:id"
              element={
                <ProtectedRoute allowedPosition="all_data">
                  <DetailData />
                </ProtectedRoute>
              }
            />

            <Route
              path="/link-generate"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <LinkGenerate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user_graph"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <DataGraphs />
                </ProtectedRoute>
              }
            />

            <Route
              path="/outsourcing-developer-data"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <DeveloperData />
                </ProtectedRoute>
              }
            />
            <Route
              path="/outsourcing-civilengineering-data"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <CivilEngineering />
                </ProtectedRoute>
              }
            />
            <Route
              path="/outsourcing-civilengineering-data/:id"
              element={<JobOfferDetail />}
            />
            <Route
              path="/outsourcing-agent-workers/:id"
              element={<WorkerListSingle />}
            />
            <Route
              path="/outsourcing-company-data"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <OutSourcingCompany />
                </ProtectedRoute>
              }
            />
            <Route
              path="/outsourcing-company-login-maker"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <AuthMaker />
                </ProtectedRoute>
              }
            />
            <Route
              path="/painting-maintenance"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <PaintingMaintenance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/automobile-manufacturing"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <AutomobileManufacturing />
                </ProtectedRoute>
              }
            />

            <Route
              path="/construction-rebar"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <ConstructionRebar />
                </ProtectedRoute>
              }
            />
            <Route
              path="/developers-data"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <DeveloperData />
                </ProtectedRoute>
              }
            />

            <Route
              path="/mechanical-engineer"
              element={
                <ProtectedRoute allowedPosition="sns_link_table">
                  <MechanicalEngineer />
                </ProtectedRoute>
              }
            />
          </Routes>
        </>
      </Box>
    </CssVarsProvider>
  );
}
