import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import OrderTable from "./OrderTable";
import { Company, CompanyApiResponse } from "../../../types/data";
import {
  DataWrapper,
  UserDataCompany,
  UserDataContainer,
  UserDataCountry,
  UserDataUniversity,
} from "../../../styles/style";
import { baseURL } from "../../../../config";
import { Button, Modal, Sheet } from "@mui/joy";
import NewUser from "./newUSer/newUser";

export default function ReportTable() {
  const [companyData, setCompanyData] = React.useState<Company[]>([]);
  const [totalCount, setTotalCount] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const fetchDocuments = async () => {
    try {
      const response = await fetch(baseURL + "/user-data/all?page=1", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CompanyApiResponse = await response.json();
      setCompanyData(data.data);
      setTotalCount(data.after_filtering_count);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchDocuments();
  }, []);

  const studentCount = companyData.filter((item) => item.place_of_study).length;
  const employeeeCount = companyData.filter((item) => item.workplace).length;
  const uniqueCountries = new Set(companyData.map((item) => item.country));
  const countryCount = uniqueCountries.size;

  const handleDownload = async () => {
    const token = localStorage.getItem("token"); // Make sure token is available and valid

    try {
      const response = await fetch(baseURL + "/user-data/download-excel", {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`, // Correct Bearer token format
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Convert the response to a blob (binary data)
      const blob = await response.blob();

      // Create a URL for the blob
      const urlObject = window.URL.createObjectURL(blob);

      // Create a link element for downloading
      const a = document.createElement("a");
      a.href = urlObject;
      // Extract filename from response headers, if present
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "download.xlsx";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "")
          .trim();
      }
      a.download = fileName;

      // Append the link to the body and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>

            <Typography color="primary" fontWeight={500} fontSize={12}>
              Reported Data
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography level="h2" component="h1" sx={{ flex: 1 }}>
              Reported Data
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Button onClick={handleDownload}>Download Excel</Button>
              <Button color="success" onClick={() => setOpen(true)}>
                New User
              </Button>
            </Box>
          </Box>
          <DataWrapper>
            <UserDataContainer>
              <h1>Total:</h1>
              <p>{totalCount || "-"}</p>
            </UserDataContainer>

            <UserDataCountry>
              <h1>Country:</h1>
              <p>{countryCount}</p>
            </UserDataCountry>
            <UserDataCompany>
              <h1>Student:</h1>
              <p>{studentCount}</p>
            </UserDataCompany>
            <UserDataUniversity>
              <h1>Employee:</h1>
              <p>{employeeeCount}</p>
            </UserDataUniversity>
          </DataWrapper>
        </Box>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{ borderRadius: "md", p: 3, boxShadow: "lg" }}
          >
            <Box>
              <NewUser setModal={setOpen} />
            </Box>
          </Sheet>
        </Modal>
        <OrderTable />
      </Box>
    </>
  );
}
