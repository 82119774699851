import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import OrderTable from "./OrderTable";
import {
  DataWrapper,
  UserDataCompany,
  UserDataContainer,
  UserDataCountry,
  UserDataUniversity,
} from "../../../../styles/style";
// import { baseURL } from "../../../../../config";

export default function ITTable() {
  // const [companyData, setCompanyData] = React.useState<Company[]>([]);
  // const [loading, setLoading] = React.useState<boolean>(true);
  // const [error, setError] = React.useState<string | null>(null);

  // const fetchDocuments = async () => {
  //   try {
  //     const response = await fetch(
  //       baseURL + "/user-data/all?page=1",
  //       {
  //         method: "GET",
  //         headers: {
  //           accept: "*/*",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data: CompanyApiResponse = await response.json();
  //     setCompanyData(data.data);
  //   } catch (error) {
  //     setError((error as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // React.useEffect(() => {
  //   fetchDocuments();
  // }, []);

  // const studentCount = companyData.filter((item) => item.place_of_study).length;
  // const employeeeCount = companyData.filter((item) => item.workplace).length;
  // const uniqueCountries = new Set(companyData.map((item) => item.country));
  // const countryCount = uniqueCountries.size;

  // React.useEffect(() => {}, [studentCount]);

  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>

            <Typography fontWeight={500} fontSize={12}>
              학원
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
              IT 데이터
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2" component="h1" sx={{ flex: 1 }}>
            IT 데이터
          </Typography>
          <DataWrapper>
            <UserDataContainer>
              <h1>Total:</h1>
              <p>0</p>
            </UserDataContainer>
            <UserDataCountry>
              <h1>Country:</h1>
              <p>0</p>
            </UserDataCountry>
            <UserDataCompany>
              <h1>Student:</h1>
              <p>0</p>
            </UserDataCompany>
            <UserDataUniversity>
              <h1>Employee:</h1>
              <p>0</p>
            </UserDataUniversity>
          </DataWrapper>
        </Box>

        <OrderTable />
      </Box>
    </>
  );
}
