import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { UserDataContainer } from "../../../styles/style";
import { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { Button, Input } from "@mui/joy";
import { baseURL } from "../../../../config";

const OutSourcingCompany = () => {
  const [workers, setWorkers] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [page] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [after_filtering_count, setAfter_filtering_count] = useState<number>(0);

  const fetchWorkers = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(baseURL + "/outsource-company/all", {
        params: {
          search,
          page,
          per_page: perPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAfter_filtering_count(res.data?.after_filtering_count || 0);

      const data = Array.isArray(res.data) ? res.data : res.data.data;
      setWorkers(data || []);
    } catch (error) {
      console.error("Failed to fetch workers:", error);
    }
  };

  useEffect(() => {
    fetchWorkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const handleSearch = () => {
    fetchWorkers();
  };

  const handleReset = () => {
    setSearch("");
    fetchWorkers();
  };

  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          //   minWidth: 0,
          height: "100dvh",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>

            <Typography color="primary" fontWeight={500} fontSize={12}>
              회사 데이터
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography level="h2" component="h1" sx={{ flex: 1 }}>
              회사 데이터
            </Typography>
          </Box>
          <div>
            <UserDataContainer style={{ width: 300 }}>
              <h1>Total:</h1>
              <p>{after_filtering_count}</p>
            </UserDataContainer>
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "end",
              mb: 4,
              alignItems: "center",
            }}
          >
            <Input
              placeholder="Search in here…"
              sx={{ flex: 2 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button onClick={handleSearch}>Search</Button>
            <Button variant="soft" onClick={handleReset}>
              RESET
            </Button>
          </Box>

          <Sheet variant="soft" sx={{ pt: 1, borderRadius: "sm" }}>
            <Table
              stripe="odd"
              hoverRow
              sx={{
                captionSide: "top",
                "& tbody": { bgcolor: "background.surface" },
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>No</th>
                  <th style={{ width: "25%" }}>Company name</th>
                  <th>Company phone</th>
                  <th>Manager phone</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {workers && workers.length > 0 ? (
                  workers.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}</td>
                      <td>{row.company_name}</td>
                      <td>{row.company_phone}</td>
                      <td>{row.manager_phone}</td>
                      <td>{row.email}</td>
                      <td>{row.company_address}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", padding: "1rem" }}
                    >
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </Box>
    </>
  );
};

export default OutSourcingCompany;
