import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { baseURL } from "../../config";
const user = JSON.parse(
  localStorage.getItem("user") || '{"role":"","position":[]}'
);

type ProtectedRouteProps = {
  allowedPosition: string;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedPosition,
  children,
}) => {
  const [userPosition, setUserPosition] = useState(user?.position);
  const [role, setRole] = useState(user?.role);

  const getUser = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseURL}/admin/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success) {
        setUserPosition(response?.data?.data?.position);
        setRole(response?.data?.data?.role);
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 1000);
  }, []);

  if (role === "SUPER_ADMIN") {
    return <>{children}</>;
  }

  const isAllowed =
    userPosition?.includes(allowedPosition) || allowedPosition === "allow";

  return isAllowed ? <>{children}</> : <Navigate to="/profile" />;
};

export default ProtectedRoute;
