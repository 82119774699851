import React, { useState } from "react";
import axios from "axios";
import { Button, Input } from "@mui/joy";

// Define the structure for each message
interface IMessage {
  role: "user" | "assistant" | "system";
  content: string;
}

function Chat() {
  const [message, setMessage] = useState<string>("");
  const [skill, setSkill] = useState<string>("");
  const [responses, setResponses] = useState<IMessage[]>([
    { role: "system", content: "Siz yordam beruvchi chatbot hisoblanasiz." },
  ]);
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [difficulty, setDifficulty] = useState<number>(1);

  const sendMessage = async (isNext: boolean = false) => {
    // Prevent sending if both message and skill are empty
    if (!message.trim() && !skill.trim()) return;

    // If it's the "Next" button, increment difficulty and question count
    if (isNext && questionCount >= 20) {
      setResponses((currentResponses) => [
        ...currentResponses,
        {
          role: "assistant",
          content: "You have completed all 20 questions. Great job!",
        },
      ]);
      return;
    }

    const autoQuestion = skill
      ? `Give me a ${
          isNext ? "harder" : "multiple choice"
        } question in JSON format for the topic ${skill} at difficulty level ${difficulty}. JSON format example: {question:""; a:""; b:""; c:""; d:""; answers:"d"}.`
      : message;

    // Add the user's request to the chat
    setResponses((currentResponses) => [
      ...currentResponses,
      { role: "user", content: autoQuestion },
    ]);

    try {
      const response = await axios.post("https://ai.urconnection.co.kr/", {
        messages: [
          {
            role: "system",
            content: "Siz yordam beruvchi chatbot hisoblanasiz.",
          },
          ...responses, // Include previous messages for context
          { role: "user", content: autoQuestion },
        ],
      });

      // Extract the assistant's response
      const assistantMessage =
        response.data?.content || "No response from assistant.";

      // Append the assistant's response to the chat
      setResponses((currentResponses) => [
        ...currentResponses,
        { role: "assistant", content: assistantMessage },
      ]);

      // Increment question count and difficulty for the next question
      if (isNext) {
        setQuestionCount((count) => count + 1);
        setDifficulty((level) => (level < 10 ? level + 1 : level)); // Max difficulty at 10
      }
    } catch (error) {
      console.error("Error sending message: ", error);
      setResponses((currentResponses) => [
        ...currentResponses,
        {
          role: "assistant",
          content: "Xatolik yuz berdi. Qayta urinib ko'ring.",
        },
      ]);
    } finally {
      setMessage(""); // Clear the input field
    }
  };

  return (
    <div>
      {/* Input for skill */}
      <Input
        type="text"
        value={skill}
        onChange={(e) => setSkill(e.target.value)}
        placeholder="Enter skill (e.g., JavaScript, Python, etc.)"
        style={{ marginBottom: "10px" }}
      />

      <Button onClick={() => sendMessage(false)}>Yuborish</Button>
      <Button
        onClick={() => sendMessage(true)}
        color="success"
        disabled={questionCount >= 20}
      >
        Next
      </Button>
      {/* Display chat messages */}
      <div>
        {responses.map((msg, index) => (
          <p key={index} style={{ margin: "5px 0" }}>
            <strong>{msg.role === "user" ? "Siz" : "Chatbot"}:</strong>{" "}
            {msg.content}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Chat;
