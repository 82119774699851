import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ColorSchemeToggle from "../others/ColorSchemeToggle";
import { closeSidebar } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config";

// User data from localStorage
const user = JSON.parse(
  localStorage.getItem("user") || '{"role":"","position":[]}'
);

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

const Sidebar: React.FC = () => {
  const [userPosition, setUserPosition] = useState(user?.position);
  const [role, setRole] = useState(user?.role);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const navigate = useNavigate();

  const checkPermission = (allowedPosition: string) => {
    if (role === "SUPER_ADMIN") return true;
    return (
      userPosition?.includes(allowedPosition) || allowedPosition === "allow"
    );
  };

  const getUser = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${baseURL}/admin/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success) {
        const user = response?.data?.data;
        setUserPosition(user.position);
        setRole(user.role);
        setName(user.name);
        setEmail(user.email);
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleLogout = () => {
    // Remove the token from localStorage or wherever it's stored
    localStorage.removeItem("token");

    localStorage.clear();
    navigate("/");
  };
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
          Connected
        </Typography>
        <ColorSchemeToggle sx={{ ml: "auto" }} />
      </Box>
      <Input
        size="sm"
        startDecorator={<SearchRoundedIcon />}
        placeholder="Search"
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          {checkPermission("general_member_table") && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <AssignmentRoundedIcon />
                    <ListItemContent>
                      <Typography sx={{ fontWeight: 600 }}>
                        일반회원 관리
                      </Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <Link
                    to="/general-member-list"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton>일반회원 목록</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link
                    to="/resume-management"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>이력서 관리</ListItemButton>
                    </ListItem>
                  </Link>
                </List>
              </Toggler>
            </ListItem>
          )}
          {checkPermission("company_table") && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <AssignmentRoundedIcon />
                    <ListItemContent>
                      <Typography sx={{ fontWeight: 600 }}>
                        기업회원 관리
                      </Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <Link
                    to="/corporate-member-list"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton>기업회원 목록</ListItemButton>
                    </ListItem>
                  </Link>
                  <Link
                    to="/job-posting-management"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>채용공고 관리</ListItemButton>
                    </ListItem>
                  </Link>
                </List>
              </Toggler>
            </ListItem>
          )}
          {checkPermission("chatting_table") && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <QuestionAnswerRoundedIcon />
                    <ListItemContent>
                      <Typography sx={{ fontWeight: 600 }}>고객상담</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <Link to="/chat" style={{ textDecoration: "none" }}>
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton>Chat</ListItemButton>
                    </ListItem>
                  </Link>
                  <ListItem>
                    <ListItemButton>비자상담 신청 내역 </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
          )}
          {checkPermission("documentation_table") && (
            <Link to="/documentation" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <InsertDriveFileIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>
                      기업 서식 자료실
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )}
          {checkPermission("visa_news_table") && (
            <Link to="/visa-news" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <NewspaperIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>
                      비자뉴스 관리
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )}

          {/* {checkPermission("user_graph") && (
            <Link to="/user_graph" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>Graphs</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )} */}

          {checkPermission("job_offer") && (
            <Link to="/company-job" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>Job Offer</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )}

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>데이터</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                {checkPermission("all_data") && (
                  <Link
                    to="/data/report-data"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            All Data
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {checkPermission("todo_table") && (
                  <Link
                    to="/data/todo_table"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            To Do
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {checkPermission("proccess_table") && (
                  <Link
                    to="/data/proccess_table"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            Proccess
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {checkPermission("done_table") && (
                  <Link
                    to="/data/done_table"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>Done</Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
              </List>
            </Toggler>
          </ListItem>

          {(checkPermission("welding_table") ||
            checkPermission("autocad_table") ||
            checkPermission("IT_table")) && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <AssignmentRoundedIcon />
                    <ListItemContent>
                      <Typography sx={{ fontWeight: 600 }}>학원</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  {checkPermission("welding_table") && (
                    <Link
                      to="/education/welding-data"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem>
                        <ListItemButton>
                          <AssignmentRoundedIcon />
                          <ListItemContent>
                            <Typography sx={{ fontWeight: 600 }}>
                              Welding
                            </Typography>
                          </ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                  {checkPermission("autocad_table") && (
                    <Link
                      to="/education/autocad-data"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem>
                        <ListItemButton>
                          <AssignmentRoundedIcon />
                          <ListItemContent>
                            <Typography sx={{ fontWeight: 600 }}>
                              Autocad
                            </Typography>
                          </ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                  {checkPermission("IT_table") && (
                    <Link
                      to="/education/IT-data"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem>
                        <ListItemButton>
                          <AssignmentRoundedIcon />
                          <ListItemContent>
                            <Typography sx={{ fontWeight: 600 }}>IT</Typography>
                          </ListItemContent>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>
                      Outsourcing
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                {checkPermission("outsource_company_table") && (
                  <Link
                    to="/outsourcing-company-data"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            회사 데이터
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {checkPermission("civil-engineering_data") && (
                  <Link
                    to="/outsourcing-civilengineering-data"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            전제 데이터
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {checkPermission("painting-maintenance") && (
                  <Link
                    to="/painting-maintenance"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            판금 도장 ･ 정비원
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}

                {checkPermission("automobile-manufacturing") && (
                  <Link
                    to="/automobile-manufacturing"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            자동차 부품제조 ･ 성형 ･ 용접 ･ 도장원
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}

                {checkPermission("construction-rebar") && (
                  <Link
                    to="/construction-rebar"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            건설 철근 형틀목공원
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}

                {checkPermission("developers-data") && (
                  <Link
                    to="/developers-data"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            IT 관련 개발자
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}

                {checkPermission("mechanical-engineer") && (
                  <Link
                    to="/mechanical-engineer"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            기계공학자
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {/* {checkPermission("developer_table") && (
                  <Link
                    to="/outsourcing-developer-data"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <AssignmentRoundedIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            개발자 데이터
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )} */}

                {checkPermission("outsourcing_login-make") && (
                  <Link
                    to="/outsourcing-company-login-maker"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <LockOpenIcon />
                        <ListItemContent>
                          <Typography sx={{ fontWeight: 600 }}>
                            Login Maker
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
              </List>
            </Toggler>
          </ListItem>

          {checkPermission("sns_link_table") && (
            <Link to="/link-generate" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <ManageAccountsIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>
                      Link Generate
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )}
          {checkPermission("control") && (
            <Link to="/control" style={{ textDecoration: "none" }}>
              <ListItem>
                <ListItemButton>
                  <ManageAccountsIcon />
                  <ListItemContent>
                    <Typography sx={{ fontWeight: 600 }}>Control</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Link>
          )}
        </List>
        <List
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          <Link to="/setting" style={{ textDecoration: "none" }}>
            <ListItem>
              <ListItemButton sx={{ fontWeight: 600 }}>
                <SettingsRoundedIcon />
                설정
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar
          variant="outlined"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{name}</Typography>
          <Typography level="body-xs">{email}</Typography>
        </Box>
        <IconButton variant="plain" color="neutral" onClick={handleLogout}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
};

export default Sidebar;
