import AIchat from "../../constant/AIchat";
import UpdateWithAi from "../../constant/AIchat/updateWithAi";

const ChattingComponent = () => {
  return (
    <div>
      <AIchat />
      {/* <UpdateWithAi /> */}
    </div>
  );
};

export default ChattingComponent;
